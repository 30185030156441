<template>
  <div class="app_box">
    <div class="login-container">
      <div class="logo-center">
        <div>
          <!-- <img src="~@/assets/image/v2_qtcns7.png"> -->
        </div>
        <div class="login-forms">
          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="loginRules"
            autocomplete="on"
            class="login-form-content"
            label-position="left"
          >
            <el-form-item prop="mobile">
              <div class="mobile-box">
                <span class="svg-container">
                  <!-- <svg-icon icon-class="user" /> -->
                </span>
                <el-input
                  ref="mobile"
                  class="input-font"
                  v-model="loginForm.mobile"
                  placeholder="请输入手机号"
                  name="mobile"
                  type="text"
                  tabindex="1"
                  autocomplete="on"
                >
                <template #append>
                  <el-button @click="getCode()">{{countdown == -1?'获取验证码':countdown == 0?'重新获取':`${countdown}秒后重新获取验证码`}}</el-button>
                </template>
                </el-input>
              </div>
            </el-form-item>
            <el-tooltip
              v-model="capsTooltip"
              content="Caps lock is On"
              placement="right"
              manual
            >
              <el-form-item prop="code">
                <div class="code-box">
                  <span class="svg-container">
                    <!-- <svg-icon icon-class="code" /> -->
                  </span>
                  <el-input
                    class="input-font"
                    ref="code"
                    v-model="loginForm.code"
                    placeholder="请输入验证码"
                    name="code"
                    tabindex="2"
                    autocomplete="on"
                    @keyup.native="checkCapslock"
                    @blur="capsTooltip = false"
                    @keyup.enter.native="handleLogin"
                  />
                </div>

              </el-form-item>
            </el-tooltip>
            <div class="login-box">
              <el-button
                :loading="loading"
                type="primary"
                class="Login"
                @click.native.prevent="handleLogin"
              >登录</el-button>
              <el-button
                class="Login"
                @click="empty"
              >清空</el-button>
            </div>

          </el-form>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import router from '../router'
import store from '../store'
import { Message } from 'element-ui'
import { Login, GetUserPages, SendRegcode } from '@/service/login/login.js'
// import SocialSign from './components/SocialSignin'
export default {
  name: 'Login',
  // components: { SocialSign },
  data() {
    const validatemobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号'))
      }
      let regex = /^1[3456789]\d{9}$/;//使用此正则即可
      if (!regex.test(this.loginForm.mobile)) {
          callback(new Error("请输入正确的手机号"));
      } else {
          callback();
      }
      // if (!validmobile(value)) {
      //   callback(new Error('Please enter the correct user name'))
      // } else {
      //   callback()
      // }
    }
    const validatecode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('验证码不能为空'))
      }
      callback()
      // if (value.length < 6) {
      //   callback(new Error('密码的长度不能小于6位数'))
      // } else {
      //   callback()
      // }
    }
    return {
      loginForm: {
        mobile: '',
        code: '',
        captcha: '',
        captchaKey: ''
      },
      loginRules: {
        mobile: [
          { required: true, trigger: 'blur', validator: validatemobile }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur', validator: validatecode }
        ],
        // captcha: [
        //   { required: true, message: '请输入验证码', trigger: 'blur' }
        // ]
      },
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      url: '',
      rSiteKey: '',
      countdown: -1,
      countdownFn: null
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
    if (this.loginForm.mobile === '') {
      this.$refs.mobile.focus()
    } else if (this.loginForm.code === '') {
      this.$refs.code.focus()
    }
  },
  destroyed() {
  },
  methods: {
    getCode() {
			if (!this.loginForm.mobile) {
        Message.error("请输入手机号码");
				return false
			}
			let regex = /^1[3456789]\d{9}$/;//使用此正则即可
			if(!regex.test(this.loginForm.mobile)){
				Message.error('请输入正确的手机号')
				return false
			}
			this.codeFn()
		},
    codeFn() {
      SendRegcode({mobile: this.loginForm.mobile}).then(async (res) => {
        console.log(res)
        if (res.data) {
          clearInterval(this.countdownFn)
					this.refreshCode()
				}
      }).catch(err => {
        console.log(err)
      })
		},
    refreshCode(){
			this.countdown = 60
			this.countdownFn = setInterval(() => {
				if (this.countdown == 1) {
					clearInterval(this.countdownFn)
				}
				this.countdown--
			}, 1000)
		},
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    },
    GetUserPagesFn(){
      let _this = this
      GetUserPages().then(async (elementres) => {
        _this.loading = false;
        let authorities = elementres
        authorities.map(item=>{
          item.authority = item.code
        })
        store.dispatch('user/setAuthorities',authorities)
        let accessRoutes = await store.dispatch(
          'permission/generateRoutes',
          authorities
        )
        // dynamically add accessible routes
        if(accessRoutes){
          router.addRoutes(accessRoutes)
        }
          _this.$router.push({
            path: '/promotion/overview',
          })
      }).catch(err => {
        console.log(err)
        _this.loading = false;
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
            const _this = this
            Login({
              mobile: _this.loginForm.mobile,
              code: _this.loginForm.code,
            }).then(res => {
              localStorage.setItem('accesstoken',res.data.accesstoken)
              localStorage.setItem('refreshtoken',res.data.refreshtoken)
              localStorage.setItem('nickname',res.data.nickname)
              _this.$router.push({
                path: '/promotion/overview',
              })
              // _this.GetUserPagesFn()
            }).catch(err => {
              console.log(err)
              _this.loading = false;
            })
          this.loading = true
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    // 重置
    empty() {
      this.loginForm = {
        mobile: '',
        code: '',
        captcha: ''
      }
      this.$refs.loginForm.resetFields()
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    
  }
}
</script>
<style lang="less" scoped>
.app_box{
  background-color: #409eff;
  min-height: 100vh;
}
  .logo-center{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 300px;
    padding: 20px 30px;
    border-radius: 10px;
    background-color: #fff;
  }

</style>
